import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

// import Banner from "../components/Banner";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import IconBoxGrid from "../components/IconBoxGrid/IconBoxGrid";

const ClassSchedulesPage = () => {
  const data = useStaticQuery(graphql`
    query schedulesIndexQuery {
      prismicSchedulesIndex {
        data {
          si_lead_text {
            text
          }
          si_intro_content {
            text
          }
          si_lower_title {
            text
          }
          si_lower_left_title {
            text
          }
          si_lower_right_title {
            text
          }
          si_lower_left_list {
            title
          }
          si_lower_right_list {
            title
          }
          si_lower_intro {
            text
            html
          }
        }
      }
    }
  `);

  const {
    si_lead_text,
    si_intro_content,
    si_lower_title,
    si_lower_intro,
    si_lower_left_title,
    si_lower_left_list,
    si_lower_right_title,
    si_lower_right_list,
  } = data.prismicSchedulesIndex.data;

  return (
    <Layout pageInfo={{ pageName: "class-schedules" }}>
      <SEO title="Class Schedules" />
      {/* <Banner title="Class Schedules" /> */}
      <section>
        <Container>
          <Row>
            <Col>
              <h2>Class Schedules</h2>

              <h4 className="subhead">{si_lead_text.text}</h4>

              <p className="intro">{si_intro_content.text}</p>

              <p className="intro font-weight-bold text-primary pt-3">
                Scroll down and click one of the training programs below to view
                class schedules and purchase your enrollment.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="dark">
        <Container>
          <Row>
            <Col className="d-flex align-items-center flex-column">
              <p className="mini-lead">What We Offer</p>
              <h3 className="text-center">Training Programs</h3>
              <p className="lead font-weight-bold">
                Click one of the training programs below to view schedules, get
                pricing, and purchase enrollment.
              </p>
              <IconBoxGrid />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col className="d-flex align-items-center flex-column">
              <p className="mini-lead">More Offerings</p>
              <h3 className="text-center">{si_lower_title.text}</h3>

              <p className="intro">{si_lower_intro.text}</p>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md={{ span: 5, offset: 1 }} className="text-center">
              <h4 className="subhead">{si_lower_left_title.text}</h4>
              <ListGroup className="list-others">
                {si_lower_left_list.map((item, i) => (
                  <ListGroup.Item key={i}>
                    <p>{item.title}</p>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>

            <Col xs="12" md={{ span: 5, offset: 0 }} className="text-center">
              <h4 className="subhead">{si_lower_right_title.text}</h4>
              <ListGroup className="list-others">
                {si_lower_right_list.map((item, i) => (
                  <ListGroup.Item key={i}>
                    <p>{item.title}</p>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ClassSchedulesPage;
